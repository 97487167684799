import React, { Component, useContext, useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "../../common/button/Button";
import Layout from "../../common/Layout/Layout";
import { Link,useHistory } from "react-router-dom";
import "../../home.css";
import Input from "../../common/input/Input";
import { AppContext } from "../../context/AppContext";
import { GlobalVariable } from "../../common/api/baseUrl";
import axios from "axios";

// import { userContext } from "../../context/carContext";
// const axios = require("axios");
const bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

const Login = ({ ...props }) => {
  const {
    userName,
    setUserName,
    userPassword,
    setUserPassWord,
    setClientId,
    setClientNumber,
    setClientEmail,
    loginMsg,
    setLoginMsg, setLogOut,
    userEmail, setUserEmail,
    userPhoneNumber, setUserPhoneNumber,
    userId, setUserId,
    userAddress, setUserAddress
  } = useContext(AppContext);
    const [isSignUp, setIsSignUp] = useState(false)
  const URL = GlobalVariable.MIDDLEWARE_URL;
  const history = useHistory()
  const loginClick = () => {
    // const pwd = bcrypt.hashSync(userPassword, salt);
    axios
      .post(`${GlobalVariable.BACKEND_API_URL}/login`, {
        username: userName,
        password: userPassword,
      })
      .then((res) => {
        if (res.data) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userName", userName);
          localStorage.setItem('email',res.data.email.trim())
          localStorage.setItem("address",res.data.address)
          localStorage.setItem("phonenumber",res.data.phonenumber)
          localStorage.setItem("clientId", res.data.clientId)
          history.push("/Dashboard");
          // setLogOut(false)
          setClientId(res.data.clientId);
          setClientNumber(res.data.phonenumber);
          setClientEmail(res.data.email.trim());
        }
      })
      .catch((err) => {
        console.log('error')
        if (
          err.response &&
          err.response.data &&
          err.response.data.errorMessage
        ) {
          setLoginMsg(err.response.data.errorMessage);
        }
      });
  };
  const signUp = () => {
    axios
      .post(`${GlobalVariable.BACKEND_API_URL}/sign-up`, {
        username: userName,
        password: userPassword,
        email: userEmail,
        phonenumber: userPhoneNumber,
        clientId: userId,
        address: userAddress,
      })
      .then((res) => {
        if (res.data) {
          history.push("/sign-up");
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errorMessage
        ) {
          setLoginMsg(err.response.data.errorMessage);
        }
      });
  };
  useEffect(() => {
    localStorage.clear();
    setUserName("");
    setUserPassWord("");
    setLogOut(false)
    setLoginMsg("")
   // window.location.reload()
  }, [])
  return (
    <Layout>
      <div>
        <div className="text-center text-white">
          <div className="full_bg">
            <section>
              <div className="p-3">
                <form>
                  <div className="mb-5 col-md-3 offset-md-8 mt-4 pt-5 text-start">
                    <div className="mt-4 mb-4">
                      <b className="login_heading">Dealer Login</b>
                    </div>
                    <div className="mb-4">
                      <p className="text-start">User Name</p>
                      <Input
                        placeholder="Enter a user name"
                        name="userName"
                        type="text"
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                          localStorage.setItem('userName',e.target.value)
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <p className="text-start">Password</p>
                      <Input
                        placeholder="Enter a user password"
                        name="userPassword"
                        type="password"
                        value={userPassword}
                        onChange={(e) => {
                          setUserPassWord(e.target.value);
                        }}
                      />
                    </div>
                    {isSignUp ?
                    <>
                    <div className="mb-2">
                      <p className="text-start">Email</p>
                      <Input
                        placeholder="Enter a user email"
                        name="userEmail"
                        type="email"
                        value={userEmail}
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <p className="text-start">Phone Number</p>
                      <Input
                        placeholder="Enter a user Phone Number"
                        name="userPhoneNumber"
                        type="number"
                        value={userPhoneNumber}
                        onChange={(e) => {
                          setUserPhoneNumber(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <p className="text-start">Client Id</p>
                      <Input
                        placeholder="Enter a user Client Id"
                        name="userId"
                        type="number"
                        value={userId}
                        onChange={(e) => {
                          setUserId(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <p className="text-start">Address</p>
                      <Input
                        placeholder="Enter a user Address"
                        name="userAddress"
                        type="text"
                        value={userAddress}
                        onChange={(e) => {
                          setUserAddress(e.target.value);
                        }}
                      />
                    </div>
                    </> : ''}
                    <span className="form-msg">{loginMsg}</span>
                    <div className="mb-5">
                      <Link to="/forgotpwd" className="forgot_password">
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="text-end d-flex justify-content-end">
                      {/* <Button
                        label="Sign Up"
                        onClick={(e)=>userEmail != "" ? signUp(e) : setIsSignUp(true)}
                      /> */}
                      <Button
                        label="Login"
                        onClick={loginClick}
                        disabled={userName == "" && userPassword == ""}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Login;