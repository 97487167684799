import React, { useState, useEffect } from "react";
import DealerAppointment from "./containers/dealer_appointment/DealerAppointment.js";
import Payment from "./containers/payment/Payment.js";
import CarDetails from "./containers/car_details/CarDetails";
import CarSelect from "./containers/car_select/CarSelect.js";
// import { Switch, Redirect, Route } from "react-router";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import Login from "./containers/login_page/Login.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Register from "./containers/login_page/Register.js";
import paymentSuccess from "./containers/payment/paymentSuccess"
import Resetpwd from "./containers/login_page/Resetpwd_page.js";
import Forgotpwd from "./containers/login_page/Forgotpwd_page.js";
import Resetpwd_success from "./containers/login_page/Resetpwd_success.js";
import Forgotpwd_success from "./containers/login_page/Forgotpwd_success.js";
import PrivacyPolicy from './common/terms-conditions/privacy-policy'
import RefundPolicy from './common/terms-conditions/refund-policy'
import TermsAndConditions from './common/terms-conditions/terms-conditions'
import SignUp_success from "./containers/login_page/SignUp_success.js";
import CarDashboard from "./containers/car_dashboard/CarDashboard.js";
export default function App() {
  const token =localStorage.getItem("token");

   return (
    <BrowserRouter>
      <Switch>
        <div>
          {/* Public routes */}
            <Route exact path="/" render={()=>(!token?<Login/>:<Redirect to="/Dashboard" />)} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/password-reset/:id/:token" component={Resetpwd} />
          <Route exact path="/password-reset_success" component={Resetpwd_success} />
          <Route exact path="/forgotpwd" component={Forgotpwd} />
          <Route exact path="/forgotpwd_success" component={Forgotpwd_success} />
          <Route exact path="/sign-up" component={SignUp_success} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/refundpolicy" component={RefundPolicy} />
          <Route exact path="/termsandconditions" component={TermsAndConditions} />
          {/* Protected routes */}
          <Route
             exact
             path="/Dashboard"
             render={() => (
              <CarDashboard />
             )}
           />
          <Route
             exact
             path="/Select"
             render={() => (
              <CarSelect />
             )}
           />
          <Route
            exact
            path="/details"
            render={() => (
              <CarDetails  />
            )}
          />
          <Route
            exact
            path="/appointment"
            render={() => (
              <DealerAppointment
              />
            )}
          />
          <Route exact path="/payment" render={() => <Payment />} />
          <Route exact path="/success" component={paymentSuccess} />
        </div>
      </Switch>
    </BrowserRouter>
  );
}
