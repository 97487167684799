import React, { useContext, useEffect, useState,useRef } from "react";
import image from "../../assets/after_login_bg.jpg";
import DropDown from "../../common/dropdown/DropDown";
import "../../home.css";
import Content from "../../common/content/Content";
import Layout from "../../common/Layout/Layout";
import { Link } from "react-router-dom";
import Button from "../../common/button/Button";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
import { Redirect,useHistory } from "react-router-dom";
import { GlobalVariable } from "../../common/api/baseUrl";


const CarSelect = () => {
  const history = useHistory()
 const {carSelect,setCarSelect,MiddlewareLogin,setOrderIdArr,setInvoiceIdArr,middlewareAuthentication} = useContext(AppContext)
 MiddlewareLogin();

 const logoutTimerIdRef = useRef(null);
 const logoutUser =()=>{
     history.push("/")
 }
 useEffect(()=>{
  localStorage.removeItem("selectedSlots")
  localStorage.removeItem("orderId")
  setOrderIdArr([])
  setInvoiceIdArr([])
  localStorage.removeItem("selectedDate")
  localStorage.removeItem("carSelect")
 },[])
//  Autologout when you enter select page
 useEffect(() => {
  axios
      .post(`${GlobalVariable.MIDDLEWARE_URL}/isLoggedIn`)
      .then((res) => {
        if (res.data && res.data.isLoggedIn != true) {
          middlewareAuthentication()

        }
      })
      .catch((err) => {
        console.log('error')
      });
//    const autoLogout = () => {
//      if (document.visibilityState === 'hidden') {
//        const timeOutId = window.setTimeout(logoutUser,12 * 60 *60 * 1000);
//        logoutTimerIdRef.current = timeOutId;
//      } else {
//        window.clearTimeout(logoutTimerIdRef.current);
//      }
//    }
//    document.addEventListener('visibilitychange', autoLogout);
//    return () => {
//      document.removeEventListener('visibilitychange', autoLogout);
//    };
 }, [])
  console.log(localStorage.getItem("token") === undefined  || !localStorage.getItem("token"))
  if(localStorage.getItem("token") === undefined  || !localStorage.getItem("token")){
   return (<Redirect to="/" />)
 }
  return (
    <Layout>
      <div style={{height: '79.1vh'}}>
        <div className="text-center text-white">
          <section className="container">
            <Content />
            <div className="row p-3">
              <div className="col-md-8 mt-4 pt-5">
                <img src={image} className="img-fluid" />{" "}
              </div>
              <div className="col-md-4 mt-4 pt-5">
                <form>
                  <div className="mb-5 choose-number">
                    <div className="mb-4 text-center">
                      <span className="text-center forgot_password fs-5 p-2">
                        Choose Number Of Cars
                      </span>
                      <DropDown  />
                    </div>
                    <Link
                      to="/details"
                      className={carSelect == 0  ? `disabled-link`  : `is_active`}
                      role="button"
                    >
                      Next
                    </Link>
                  </div>
                
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
export default CarSelect;
