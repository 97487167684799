import React, { useContext, useRef, useState,useEffect } from "react";
import DropDown from "../../common/dropdown/DropDown";
import Input from "../../common/input/Input";
import "../../home.css";
import Content from "../../common/content/Content";
import Layout from "../../common/Layout/Layout.js";
import DealerAppointment from "../dealer_appointment/DealerAppointment";
import { AppContext } from "../../context/AppContext";
import { CarDetailsForm } from "../../common/form/carDetailsForm";
import { Redirect,useHistory } from "react-router-dom";

const CarDetails = () => {
  const history = useHistory();
  const {
    carSelect,
    carDetailsArr,
    updateCarDetailsFormErrors,
    setAllowToPay,
  } = useContext(AppContext);
  const carDetailsFormRef = useRef();
  const carDetailsFormErrors = {};
  var rows = [1],
    i = 1,
    len = carSelect;
  while (++i <= len) rows.push(i);

  const logoutTimerIdRef = useRef(null);
  const logoutUser =()=>{
      history.push("/")
  }
  useEffect(() => {
    // const autoLogout = () => {
    //   if (document.visibilityState === 'hidden') {
    //     const timeOutId = window.setTimeout(logoutUser,12 * 60 *60 * 1000);
    //     logoutTimerIdRef.current = timeOutId;
    //   } else {
    //     window.clearTimeout(logoutTimerIdRef.current);
    //   }
    // }
    // document.addEventListener('visibilitychange', autoLogout);
    // return () => {
    //   document.removeEventListener('visibilitychange', autoLogout);
    // };
  }, [])
 
  const validateFormDetails = () => {
    const currentCarDetailsForm = carDetailsFormRef.current;
    if (
      currentCarDetailsForm.querySelectorAll("#model:invalid").length > 0 ||
      currentCarDetailsForm.querySelectorAll("#year:invalid").length > 0 ||
      currentCarDetailsForm.querySelectorAll("#odoMeter:invalid").length > 0
    ) {
    
      currentCarDetailsForm
        .querySelectorAll("#model:invalid")
        .forEach((invalidInputEl) => {
          const indexVal = invalidInputEl.getAttribute("dataindex");
          const inputName = invalidInputEl.getAttribute("name");
          if (!carDetailsFormErrors[indexVal]) {
            carDetailsFormErrors[indexVal] = {};
          }
          carDetailsFormErrors[indexVal][inputName] = `Field is required`;
        });
      currentCarDetailsForm
        .querySelectorAll("#year:invalid")
        .forEach((invalidInputEl) => {
          const indexVal = invalidInputEl.getAttribute("dataindex");
          const inputName = invalidInputEl.getAttribute("name");
          if (!carDetailsFormErrors[indexVal]) {
            carDetailsFormErrors[indexVal] = {};
          }
          carDetailsFormErrors[indexVal][inputName] = `Field is required`;
        });
      currentCarDetailsForm
        .querySelectorAll("#odoMeter:invalid")
        .forEach((invalidInputEl) => {
          const indexVal = invalidInputEl.getAttribute("dataindex");
          const inputName = invalidInputEl.getAttribute("name");
          if (!carDetailsFormErrors[indexVal]) {
            carDetailsFormErrors[indexVal] = {};
          }
          carDetailsFormErrors[indexVal][inputName] = `Field is required`;
        });
      updateCarDetailsFormErrors(carDetailsFormErrors);
      return false;
    }
    updateCarDetailsFormErrors({});
    return true;
  };
  if(localStorage.getItem("token") === undefined  || !localStorage.getItem("token")){
    return (<Redirect to="/" />)
  } 
  return (
    <Layout>
      <div>
        <div className="text-center text-white">
          <section className="container body_height car_choosen">
            <Content />
            <div className="row">
              <div className="col-md-4 mb-3">
                <p className="forgot_password text-start mt-3">
                  Number of Cars Chosen
                </p>
                <DropDown />
                <span className="text-span">
                  $150(Including GST) for each vehicle
                </span>
              </div>

              <div className="col-md-4 mb-3">
                <form ref={carDetailsFormRef}>
                  {rows.map((e, i) => (
                    <div className="one mb-4" key={i}>
                      <p className="forgot_password text-center circle">
                        <b>{i + 1}</b>
                      </p>
                      <CarDetailsForm indexVal={i} />
                    </div>
                  ))}
                </form>
              </div>
              <DealerAppointment validateFormDetails={validateFormDetails} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
export default CarDetails;
