import React, { useContext, useEffect, useState } from "react";
import "../../home.css";
import { useHistory } from "react-router-dom";
import TimeSlots from "../../common/slots/TimeSlots.js";
import { AppContext } from "../../context/AppContext";
import { Redirect } from "react-router-dom";

const DealerAppointment = ({ validateFormDetails }) => {
  const history = useHistory();
  const {
    availableSlotsRequest,
    avaliableSlotsMsg,
    selectedSlots,
    carSelect,
    MultipleBooking,
    validation,
    errMsg,
    setErrMsg,
    selectedDate,
    formValidation,
    dateMsg, setdateMsg,
    allowToPay,orderIdArr,bookingSucess,avaliableSlots
  } = useContext(AppContext);
  let today = new Date().toISOString().split('T')[0];
  const validationHandle = () => {
    // 
    if (validation && selectedSlots.length === parseFloat(carSelect)) {
      MultipleBooking();
      setErrMsg(false)
      setdateMsg(false)
    } else {
      // history.push("/details");
      selectedDate ? setErrMsg(true) : setdateMsg(true)
      //selectedDate ? setdateMsg(false) : setErrMsg(false)
    }
  }
  if(bookingSucess) {
    return <Redirect to='/payment' />} 
  return (
    <div className="time_style col-md-4 mb-3">
      <div className="mb-4">
        <p>Choose Date</p>
        <form>
          <input
            type="date"
            id="dateSelect"
            min={today}
            name="birthday"
            className="p-2 form-control"
            onChange={(e) => {
              availableSlotsRequest(e.target.value);
            }}
          />
          {!selectedDate && dateMsg ? <span className="date-msg">*Please select date</span> : ""}
          {avaliableSlotsMsg ? <span className="date-msg">*No service</span> : ""}
          { avaliableSlots.length !==0 &&  avaliableSlots.length < parseInt(carSelect) ? 
        <span className="date-msg">
        *NO SLOT FOR ALL CARS, 
        SELECT LESS CARS 
        OR SELECT DIFFERENT DATE
      </span> : ""}
        </form>
      </div>
      <TimeSlots />
      {!validation ?
        <div className="date-msg">
          * Please select correct number of slots
        </div> : ""
      }
      <div className="col-md-10 ">
        <button
          className="btn-custom"
          onClick={() => {
            if (validateFormDetails && validateFormDetails()) {
              validationHandle()
            }
          }}
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

export default DealerAppointment;
