import React from "react";
import Layout from "../Layout/Layout";
import "../terms-conditions/terms-conditions.css"
 

const PrivacyPolicy = (props) => {
        return (
            <Layout>
           
                <div className="TermsConditionsContainer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="TermsConditionsTitle">PRIVACY POLICY</h1>
                                <p>The information of this website visitors is mainly gathered when it is submitted internationally or voluntarily.
                                     Information includes personal information such as name,
                                      email address and contact number.
                                </p>
                                <p>The main purpose of collecting the data is to provide our service and product or the information is related to the product or service which we offer to our customer.</p>
                                <p>In some situation, we might gather the information from the third party, 
                                    but we will take all appropriate measures to make sure the source of information is declared and visible.</p>
                                    <h5>Confidential &amp;
                                         Financial Information:<br/>
                                         </h5>
                                         <p>Information such as cultural background, 
                                             race, political links, membership with professional organization, 
                                             judicial records and/or information on health. 
                                             Financial details include credit card, 
                                             debit card or any other method of electronic payments. 
                                             All financial information of our customer is collected and saved on a secure processing server.</p>
                                         <p>Confidential and financial data is gathered mainly to provide our service and product to our customer, 
                                             or information related to the product or service to our customer.</p>
                                         <p>Further use of personal information&amp;
                                             release to third party.</p>
                                             <p>Information submitted by the website visitor might be used for minor reason which are similar to main reason. 
                                                 Information submitted by visitors to this site may also be used for secondary purposes closely related to the main reason. 
                                                 Sensitive and/or information of the client will not be given to any third organisation beside the following condition.</p>
                                             <p>As per the consent, personal information and data could be disclosed to the third party if it is essential to gather the main information. 
                                                 For instance, the third party is involved in legal proceeding against someone who is involved, compromised our legal obligations or property and/or if the law demands us to reveal the information.</p>
                                             <h5>Security of information:<br/>
                                             </h5>
                                             <p>Information stored by SafeBuy is being saved from damage, loss, malpractice unauthorised use or disclosing it under the policy and procedure of the company. 
                                                 Unnecessary information would be deleted on regular basis or as decided by our management team.</p>
                                             <h5>Access of information:</h5>
                                             <p>Clienthas permission toretrieve, reviseor delete private information from our database.</p>
                                             <p>It is advised to submit a written complaint through e-mail or use our additional communication method if the client desire to access the information. 
                                                 Privacy Statement are subject to change, for the latest updatesplease get in touch with SafeBuy. 
                                                 All requests for information regarding our privacy policyare directed towards ourcontact phone number referred to SafeBuy website.</p>
                                             </div>
                                             </div>
                            </div>
                        </div>
                    
                    </Layout>
    )


}
export default PrivacyPolicy