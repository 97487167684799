import React from "react";
import Layout from "../Layout/Layout";
import "./terms-conditions.css";

const RefundPolicy = (props) => {

        return (
            <Layout>
           
            <div className="TermsConditionsContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="TermsConditionsTitle">
                                BOOKING, REFUND AND CANCELLATION POLICY
                            </h1>
                            <p>
                                <strong>Note:</strong>
                            </p>
                            <p>
                                Please book at least 2.5 hours before
                                inspection time. Alternatively, call
                                customer service for urgent booking.
                            </p>
                            <p>
                                <strong>Service Cover:</strong>
                                <br />
                            </p>
                            <p>
                                Standard inspection service covers up to
                                80km from Melbourne CBD.
                            </p>
                            <p>
                                Comprehensive and Premium inspections prices
                                increases after 80km from Melbourne
                                CBD.&nbsp;
                            </p>
                            <p>
                                <em>Comprehensive Inspection</em>
                            </p>
                            <ul>
                                <li>
                                    <em>80kms to 120kms is $280</em>
                                </li>
                                <li>
                                    <em>120kms to 160kms is $320</em>
                                </li>
                            </ul>
                            <p>
                                <em>Premium Inspection</em>
                            </p>
                            <ul>
                                <li>
                                    <em>80kms to 120kms is $340</em>
                                </li>
                                <li>
                                    <em>120kms to 160kms is $380</em>
                                </li>
                            </ul>
                            <p />
                            <p />
                            <p>
                                <strong>
                                    <em>
                                        Comprehensive and Premium
                                        inspections
                                    </em>
                                </strong>
                                &nbsp;covers up to 160 kms from Melbourne
                                CBD.
                            </p>
                            <p>
                                For inspection above 160 km please contact
                                us onadmin@safe-buy.com.auor use web chat on{" "}
                                <a href="https://www.safe-buy.com.au/">
                                    www.safe-buy.com.au
                                </a>
                            </p>
                            <p>
                                Our Roadworthy Inspection workshop is in the
                                northern suburb. We will confirm RWC booking
                                via phone call, text message or an email.
                                Workshop address will be provided with your
                                booking confirmation.
                            </p>
                            <p>
                                <strong>
                                    <br />
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    Refund, Fee and Exclusion Policy:
                                </strong>
                                <br />
                            </p>
                            <p>
                                1. Booking fee $150 is not refundable 6
                                hours prior of inspection due to change of
                                mind or change in agreement between
                                buyer/seller or rescheduling date or time or
                                location.
                            </p>
                            <p>
                                2. Customer can cancel the booking via
                                cancellation button available on their
                                booking confirmation email.Alternatively
                                email us on admin@safe-buy.com.au for
                                cancellation.
                            </p>
                            <p>
                                3. Below booking fee is not refundable if
                                customer likes to cancel or reschedule the
                                booking within 2 hours of inspection.
                            </p>
                            <p />
                            <ul>
                                <li>
                                    <em>
                                        Booking fee $150 up to 80km&nbsp;
                                    </em>
                                </li>
                                <li>
                                    <em>Booking fee $190 from 80-120km</em>
                                </li>
                                <li>
                                    <em>Booking fee $230 from 120-160km</em>
                                </li>
                            </ul>
                            <p>
                                <strong>Note:</strong>
                            </p>
                            <p />
                            <ul>
                                <li>
                                    Inspector will not wait more than
                                    10-15mins at vehicle’s location if
                                    vehicle not available. In case of longer
                                    wait Inspection service charges will not
                                    be refundable.
                                </li>
                                <li>
                                    Car History and PPSR charges are not
                                    refundable as SafeBuy complete those
                                    checks prior to inspection.
                                </li>
                                <li>
                                    Please allow up to 5-10 working days for
                                    refund to appear into your account.
                                </li>
                            </ul>
                            <p />
                            <p />
                            <p>
                                4. SafeBuy vehicle inspection services
                                include that you will be provided with an
                                inspection report and you agree to pay the
                                charges as mentioned on the website.
                            </p>
                            <p>
                                5. SafeBuy inspection fee may be revised.
                                Please visit our website{" "}
                                <a href="https://www.safe-buy.com.au/">
                                    www.safe-buy.com.au
                                </a>{" "}
                                for recent charges.&nbsp;
                            </p>
                            <p>
                                6. Copy of the inspection report can be
                                obtained by seller at an additional
                                cost.&nbsp;
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </Layout>
        
        )
    

}
export default RefundPolicy;