import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/safe-buy-logo.png";
import { AppContext } from "../../../context/AppContext";
import "../../../home.css";
const Header = (...props) => {
  const {setCarSelect,setAvaliableSlots,
    updateSelectedSlots,logOut} = useContext(AppContext)
 
  const Clear =()=>{
    setCarSelect(0)
    setAvaliableSlots("");
    updateSelectedSlots("");
    if (sessionStorage) {
      sessionStorage.removeItem('sessionTimer');
    }
  }
  return (
    <header>
      <div>
        <div className="text-center text-white">
          <div className="container-fluid pt-2 header_bg">
            <header className="top_header">
              <div className="container p-2">
                <div className="row">
                  <div className="col-md-2">
                    <Link className="navbar-brand" to="/select">
                      {" "}
                      <img src={Logo} style={{ height:'52px', width:'160px' }}/>
                    </Link>
                  </div>
                  <div className="col-md-10 header-link mt-3">
                    <ul className="d-block text-sm-end p-0">
                      <li className="nav-item d-inline ">
                        <span className="mb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
                          </svg>
                          <a
                            href="mailto: admin@safe-buy.com.au"
                            className="text-decoration-none"
                          >
                            {" "}
                            admin@safe-buy.com.au
                          </a>
                        </span>
                      </li>
                      <li className="ms-2 d-inline ">|</li>
                      <li className="nav-item ms-2 d-inline ">
                        <span className="mb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-telephone-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            ></path>
                          </svg>
                          <a href="tel:0386767692" className="text-decoration-none">
                            {" "}
                            03 8676 7692
                          </a>
                        </span>
                      </li>
                       {localStorage.getItem("clientId") ? 
                       <>
                       <li className="ms-2 d-inline ">|</li>
                      <li className="nav-item ms-2 d-inline">
                        <span className="mb-3">
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"></path>
                                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"></path>
                                        </svg>
                                        <Link className="text-decoration-none" role="button" onClick={Clear} to="/"> Sign Out </Link>
                        </span>
                      </li>
                       </>
                       : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;