import React, { useState, useEffect } from "react";
import { AppContext } from "./AppContext";
import moment from "moment";
import axios from "axios";
import { GlobalVariable } from "../common/api/baseUrl";
import { useHistory } from "react-router-dom";

export const AppContextProvider = ({ children }) => {
  const history = useHistory();
  const URL = GlobalVariable.MIDDLEWARE_URL;
  axios.defaults = Object.assign(axios.defaults, {
    withCredentials: true,
    baseURL: URL,
  });

  const [carSelect, setCarSelect] = useState(0);
  const [dateSelect, setDateSelect] = useState("");
  const [avaliableSlots, setAvaliableSlots] = useState([]);
  const [avaliableSlotsMsg, setAvaliableSlotsMsg] = useState("");
  const [validateSlotsMsg, setValidateSlotsMsg] = useState();
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassWord] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userId, setUserId] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [resetPwd, setResetPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [price, setPrice] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [registerdEmail, setRegisterdEmail] = useState("");
  const [allowToPay, setAllowToPay] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [clientId, setClientId] = useState();
  const [invoiceIds, setInvoiceIds] = useState([]);
  const [bookingIds, setBookingIds] = useState([]);
  const [clientSecretId, setClientSecretId] = useState();
  const [clientEmail, setClientEmail] = useState();
  const [clientNumber, setClientNumber] = useState();
  const [orderId, setOrderId] = useState(0);
  const [succeeded, setSucceeded] = useState(false);
  const [loginMsg, setLoginMsg] = useState("");
  const [resetMsg, setResetMsg] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [dateMsg, setdateMsg] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [carDetailsArr, setCarDetailsArr] = useState([
    {
      model: "",
      year: 0,
      odoMeter: 0,
      regNo: "",
      carNotes: "",
    },
  ]);
  const [carDetailsFormErrors, setCarDetailsFormErrors] = useState({});
  const [bookingSucess, setBookingSucess] = useState(false);
  const [endPoints, setEndPints] = useState([]);
  const [orderIdArr, setOrderIdArr] = useState([]);
  const [invoiceIdArr, setInvoiceIdArr] = useState([]);
  let globalTimer;

  var rows = [1],
    i = 1,
    len = carSelect; //2
  while (++i <= len) rows.push(i);

  const validateInterval = (selectedSlots) => {
    if (!selectedSlots || !selectedSlots.length) return false;
    if (selectedSlots.length !== parseFloat(carSelect)) return false;

    if (selectedSlots.length === 1) return true;

    let sortedSlots = selectedSlots.sort();

    let validInterval = true;

    for (
      let current = 0, next = 1;
      current < sortedSlots.length && next < sortedSlots.length;
      current++, next++
    ) {
      validInterval =
        validInterval &&
        moment
          .duration(
            moment(selectedDate + " " + sortedSlots[next]).diff(
              moment(selectedDate + " " + sortedSlots[current])
            )
          )
          .asHours() === 1.5;
    }

    return validInterval; // O(1)
  };

  const validation = validateInterval(selectedSlots);

  const slotValidate = () => {
    if (selectedSlots.length != carSelect) {
      setValidateSlotsMsg(true);
    } else {
      setValidateSlotsMsg(false);
    }
  };

  const availableSlotsRequest = async (result) => {
    setOrderIdArr([]);
    setInvoiceIdArr([]);
    setEndPints([]);
    setSelectedDate(result);
    localStorage.setItem("selectedDate", result);
    await axios
      .get(`${URL}/schedule/available-slots?service_id=9&date=${result}`)
      .then((res) => {
        setAvaliableSlots(res.data);
        if (res.data.length < 1) {
          setAvaliableSlotsMsg(true);
        } else {
          setAvaliableSlotsMsg(false);
          localStorage.removeItem("selectedSlots", selectedSlots);
          setSelectedSlots([]);
        }
      });
  };

  const login = async () => {
    await axios.post(`${URL}/api/auth`).then((res) => { });
  };

  const MiddlewareLogin = () => {
    
  };

  useEffect(() => {
    if (!logOut) {
      if (globalTimer) {
        clearInterval(globalTimer);
      }
    }
    if (sessionStorage && logOut) {
      const sessionTimerFromStore = sessionStorage.getItem('sessionTimer');
      if (!sessionTimerFromStore) {
        sessionStorage.setItem('sessionTimer', GlobalVariable.IDLE_SESSION_TIMEOUT);
        middlewareAuthentication();
      }
    }

    if (sessionStorage && logOut) {
      globalTimer = setInterval(() => {
          const sessionTimer = sessionStorage.getItem('sessionTimer') || GlobalVariable.IDLE_SESSION_TIMEOUT;
          sessionStorage.setItem('sessionTimer', sessionTimer > 0 ? sessionTimer - 1 : 0);
          if (sessionTimer <= 0) {
            sessionStorage.setItem('sessionTimer', GlobalVariable.IDLE_SESSION_TIMEOUT);
            middlewareAuthentication();
          }
      }, 1000 * 60);
    }
  }, [logOut]);

  const middlewareAuthentication = async () => {
      await axios.post(`${URL}/auth`);
      // .then((res) => console.log("login", res.data));
      await axios.post(`${URL}/isLoggedIn`);
      // .then((res) => console.log("session", res.data));
      try {
      await axios.post(`${URL}/`);
      } catch (err) {
        // err status code is 500
        // setTokenInvalid(true)
      }
      // .then((res) => console.log("token", res.data));
  }

  const service = async () => {
    await axios.get(`${URL}/services?id=9`).then((res) => {
      setPrice(res.data.price);
    });
  };

  const serviceCategories = async () => {
    await axios.get(`${URL}/cat`).then((res) => {
      // setPrice(res.data.price);
    });
  };
  const regNoDefault = 0
  const MultipleBookingHelper = carDetailsArr.map((carDetail, i) => {
    const requestBody = {
      provider_id: 1,
      service_id: 9,
      date: selectedDate,
      time: selectedSlots[i],
      clientData : {  
           name:localStorage.getItem("userName"), 
           email: localStorage.getItem("email"), 
           phone: localStorage.getItem("phonenumber") 
      },
      additional_fields:{
        "174f93dea26a3e68edd5863971f1a776": localStorage.getItem("userName"),
        "1e03f7a8574a6acea8d9871c3fb65b25": localStorage.getItem("phonenumber"),
        "2d42cf2bb70e82898ba9ffc46dba74f2": localStorage.getItem("address"),
        "9f785f73333f13a44dbd5cc9df0060f4": carDetail.model,
        "73de0266822ac41e149412761cf1b731": carDetail.year,
        "ac59cc4cfbdefb7805668ea679018e02": carDetail.odoMeter,
        "a25f4d5f4cba4c7428d27430d7dca0d6": "Rego",
        "661e7ff7914016277b3e4de4c62fb285": carDetail.regNo || regNoDefault,
        "a6bf0d4d47602038ef0bbb2fd3ff6f22": null,
        "36820785829f0404de8fb18d886350a7": carDetail.carNotes,
        "handle_invoice": true,
        "products": []
          // "a22dd47a41dc2e7c73df96c56ef10a30":carDetail.model,
          // "6f8cd9ffd70dae7f8ed43100d88617c3":carDetail.odoMeter,
          // "76bab6268b4bdc0754b0fda5281c6ce0":carDetail.regNo || regNoDefault,
          // "de5766cce39481bdf61b9b829e415f0c":"price",
          // "824dc984428b6feb98876442ea3aaedf":carDetail.carNotes,
          // "8fb7c4a07a48e5db9bb354cffbec3ce9":carDetail.year,
          //  "handle_invoice": true,
          //  "products": []
      }
    };
    return requestBody;
  });
  // const MultipleBookingHelper = carDetailsArr.map((carDetail, i) => {
  //   const requestBody = {
  //     start_datetime: selectedDate + " " + selectedSlots[i],
  //     accept_payment: "true",
  //     payment_processor: "delay",
  //     price: price,
  //     provider_id: 1,
  //     service_id: 8,
  //     client_id: localStorage.getItem("clientId"),
  //     additional_fields: [
  //       {
  //         field: "9f785f73333f13a44dbd5cc9df0060f4",
  //         value: carDetail.model,
  //         file_hash: null,
  //         id: 1,
  //       },
  //       {
  //         field: "73de0266822ac41e149412761cf1b731",
  //         value: carDetail.year,
  //         file_hash: null,
  //         id: 2,
  //       },
  //       {
  //         field: "ac59cc4cfbdefb7805668ea679018e02",
  //         value: carDetail.odoMeter,
  //         file_hash: null,
  //         id: 3,
  //       },
  //       {
  //         field: "661e7ff7914016277b3e4de4c62fb285",
  //         value: carDetail.regNo || regNoDefault,
  //         file_hash: null,
  //         id: 4,
  //       },
  //       {
  //         field: "36820785829f0404de8fb18d886350a7",
  //         value: carDetail.carNotes,
  //         file_hash: null,
  //         id: 5,
  //       },
  //       {
  //         field: "174f93dea26a3e68edd5863971f1a776",
  //         value: localStorage.getItem("userName"),
  //         file_hash: null,
  //       },
  //       {
  //         field: "1e03f7a8574a6acea8d9871c3fb65b25",
  //         value: localStorage.getItem("phonenumber"),
  //         file_hash: null,
  //       },
  //       {
  //         field: "2d42cf2bb70e82898ba9ffc46dba74f2",
  //         value: localStorage.getItem("address"),
  //         file_hash: null,
  //       },
  //       {
  //         field: "a25f4d5f4cba4c7428d27430d7dca0d6",
  //         value: "Rego",
  //         file_hash: null,
  //       },
  //       {
  //         field: "a6bf0d4d47602038ef0bbb2fd3ff6f22",
  //         value: null,
  //         file_hash: null,
  //       },
  //     ],
  //   };
  //   return requestBody;
  // });

  const accept_payment = async () => {
    await axios
      .all(orderIdArr.map((id) => axios.get(`${URL}/bookings?id=${id}`)))
      .then((res) => {
        res.map((res) => {
          invoiceIdArr.push(res.data.invoice_id);
          setInvoiceIdArr(invoiceIdArr);
        });
      });
  }
  const updateBookingSucess = () => {
 
    invoiceIdArr.forEach((id,idx)=>{
      console.log(idx,parseFloat(carSelect))
      if((idx+1) === parseFloat(carSelect)){
        setBookingSucess(true);
      }  
    })
  };
  const Booking = async () => {
    const retryEndpoint = async (idx) => {
      const response = await axios.post(`${URL}/book`,MultipleBookingHelper[idx]);
      if (response.data && response.data.result) {
        invoiceIdArr.push(response.data.result.invoice.id)
        setInvoiceIdArr(invoiceIdArr)
        return response.data;
      } else if (response.data && response.data.error) {
        await retryEndpoint(idx);
      }
    }
   carDetailsArr.map((endpoint, idx) => retryEndpoint(idx))
  };

  const getBooking = async () => {
    await axios
      .all(orderIdArr.map((id) => axios.get(`${URL}/bookings?id=${id}`)))
      .then((res) => {
        res.map((res) => {
          invoiceIdArr.push(res.data.invoice_id);
        });
      });
  };

  const MultipleBooking = () => {
    Booking().finally(()=>{
      setBookingSucess(true)
    })
  };

  const updateSelectedSlotsMoment = (carSelectInt, selectedSlotIndex) => {
    if (avaliableSlots.length - 1 < (selectedSlotIndex + carSelectInt - 1)) {
      return false;
    }
    let canUpdate = true;
    for (i = selectedSlotIndex; i <= (selectedSlotIndex + carSelectInt - 1); i++) {
      if (avaliableSlots[i + 1]) {
        const slotTimeDiff = moment
          .duration(
            moment(selectedDate + " " + avaliableSlots[i + 1].time).diff(
              moment(selectedDate + " " + avaliableSlots[i].time)
            )
          )
          .asHours() <= 1.5;
        canUpdate = canUpdate && slotTimeDiff
      }
    }
    console.log('canUpdate => ', canUpdate);
    return canUpdate;
  }

  const updateSelectedSlots = (slot, selectedSlotIndex) => {
    const carSelectInt = parseFloat(carSelect);
    if(carSelectInt > avaliableSlots.length){
      return false
    }
    if ((!selectedSlots || selectedSlots.length === 0) && updateSelectedSlotsMoment(carSelectInt, selectedSlotIndex)) {
        const slotsToBeselected = avaliableSlots.slice(selectedSlotIndex, (selectedSlotIndex + carSelectInt));
      selectedSlots.push(...slotsToBeselected.map(a => a.time));
    } else {
      const slotIndex = selectedSlots.indexOf(slot);
      if (slotIndex !== -1) {
        selectedSlots.splice(slotIndex, 1);
      } else {
        selectedSlots.push(slot);
      }
    }
    setSelectedSlots([...selectedSlots]);
    localStorage.setItem("selectedSlots", [selectedSlots]);
  };

  const updateCarDetailsFormErrors = (carDetailsFormErrorsProp) => {
    setCarDetailsFormErrors(carDetailsFormErrorsProp);
  };

  //=====================================================================================

  //=====================================================================================

  //---------------- payment ----------------

  const Stripe_payment = async () => {
    await axios
      .all(
        invoiceIdArr.map((id) =>
          axios.post(`${GlobalVariable.MIDDLEWARE_URL}/accept-payment`, { id: id, payment_processor: "stripe" })
        )
      )
      .then((res) => {
        //console.log(res)
      });
  };

  //---------------- payment -----------------

  return (
    <AppContext.Provider
      value={{
        userName,
        setUserName,
        userPassword,
        setUserPassWord,
        carSelect,
        setCarSelect,
        carDetailsArr,
        setCarDetailsArr,
        validateInterval,
        selectedSlots,
        dateSelect,
        setDateSelect,
        availableSlotsRequest,
        avaliableSlots,
        login,
        MiddlewareLogin,
        middlewareAuthentication,
        service,
        Booking,
        MultipleBooking,
        updateSelectedSlots,
        price,
        selectedDate,
        validation,
        errMsg,
        setErrMsg,
        Stripe_payment,
        // mainpayment,
        serviceCategories,
        clientId,
        setClientId,
        clientEmail,
        setClientEmail,
        clientNumber,
        setClientNumber,
        dateMsg,
        setdateMsg,
        loginMsg,
        setLoginMsg,
        MultipleBooking,
        totalCost,
        setTotalCost,
        setClientSecretId,
        getBooking,
        accept_payment,
        bookingIds,
        resetPwd,
        setResetPwd,
        confirmPwd,
        setConfirmPwd,
        carDetailsFormErrors,
        updateCarDetailsFormErrors,
        allowToPay,
        setAllowToPay,
        setRegisterdEmail,
        registerdEmail,
        resetMsg,
        setResetMsg,
        avaliableSlotsMsg,
        setAvaliableSlotsMsg,
        slotValidate,
        validateSlotsMsg,
        orderIdArr,
        logOut,
        setLogOut,
        setOrderIdArr,
        setInvoiceIdArr,
        setAvaliableSlots,
        setBookingSucess,
        bookingSucess,
        succeeded,
        setSucceeded, 
        avaliableSlots, carSelect,
        userEmail, setUserEmail,
        userPhoneNumber, setUserPhoneNumber,
        userId, setUserId,
        userAddress, setUserAddress
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
