import React, { useContext, useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import api from "../api/stripeapi";
import '../form/checkoutform.css'
import Image from '../../assets/payment.jpg'
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";

export default function CheckoutForm() {
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { price, carSelect, setClientSecretId, Stripe_payment,succeeded, setSucceeded, orderIdArr } = useContext(AppContext)

  let total = price * localStorage.getItem('carSelect')
  let finalPrice = total * 100

  let history = useHistory()

  useEffect(() => {
    api
      .createPaymentIntent({
        payment_method_types: ["card"],
        amount: finalPrice,
        currency: "AUD"
      })
      .then(res => {
        setClientSecret(res.client_secret);
        // console.log("res stripe:",res.client_secret);
      })
      .catch(err => {
        setError(err.message);
      });
  }, [price]);

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    // console.log("total:","price",price ,"carselect", localStorage.getItem('carSelect'));
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value
        }
      }
    });
    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);

    } else {
      setError(null);
      setSucceeded(true);
      Stripe_payment();
      setProcessing(false);
      setMetadata(payload.paymentIntent);
    }
  };
  // console.log("clientSecret:",clientSecret);
  const renderSuccess = () => {
    return (
      <div>
        {history.push("/success")}
      </div>
    );
  };
  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325D",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "14px",
          boxSizing: "2px",
          "::placeholder": {
            color: "#AAB7C4"
          }
        },
        invalid: {
          color: "#FA755A",
          iconColor: "#FA755A"
        }
      }
    };
    return (
      <form onSubmit={handleSubmit}>
        <div className="card card-responsive" >
          <div className="sr-combo-inputs">
            <div className="card-header-wrapper">
              <text className="card-header">Card Details</text>
              <img className="card-image" src={Image} />
            </div>
            <div className="sr-combo-inputs-row">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Full name"
                autoComplete="cardholder"
                className="sr-input"
              />
            </div>
            <div className="sr-combo-inputs-row">
              <CardElement
                className=" sr-card-element"
                options={options}
              />
            </div>
          </div>
          <button
            className="btn-btn"
          >
            {processing ?
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
              : "PAYNOW"}
          </button>
        </div>
      </form>
    );
  };
  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
}
